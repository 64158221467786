import { Button, Grid, TextField } from '@mui/material'
import React, { useState, useCallback, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
// import Dropzone from 'react-dropzone'
import { saveAs } from 'filesaver.js-npm';
import { useDropzone } from 'react-dropzone'
import ReactLoading from 'react-loading';



const ImageManipulation = ({ imgFile, fileName, ...rest }) => {
    const editor = useRef()
    const [position, setposition] = useState({ x: 0.5, y: 0.5 })
    const [allowZoomOut, setallowZoomOut] = useState(false)
    const [scale, setscale] = useState(1)
    const [rotate, setrotate] = useState(0)
    const [borderRadius, setborderRadius] = useState(0)
    const [width, setwidth] = useState(300)
    const [height, setheight] = useState(400)
    const [isTransparent, setisTransparent] = useState(false)
    const [showGrid, setshowGrid] = useState(false)
    const [editedImage, seteditedImage] = useState(undefined)

    const rotateLeft = (e) => {
        e.preventDefault()
        setrotate((rotate - 90) % 360)
    }

    const rotateRight = (e) => {
        e.preventDefault()
        setrotate((rotate + 90) % 360)
    }
    const handlePositionChange = (position) => {
        setposition(position)
    }


    const handleSave = (e) => {
        const img = editor.current?.getImageScaledToCanvas()

        const rect = editor.current?.getCroppingRect()

        if (!img || !rect) return

        seteditedImage(
            {
                img,
                rect,
                scale: scale,
                width: width,
                height: height,
                borderRadius: borderRadius,
            }
        )
        img.toBlob((blob) => saveAs(blob, fileName))
    }

    return (

        <div>
            <AvatarEditor
                ref={editor}
                scale={scale}
                width={width}
                height={height}
                position={position}
                // showGrid={showGrid}
                onPositionChange={handlePositionChange}
                rotate={rotate}
                borderRadius={
                    width / (100 / borderRadius)
                }
                image={imgFile}
            // backgroundColor={this.state.backgroundColor}
            // onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
            // onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
            // onImageReady={this.logCallback.bind(this, 'onImageReady')}
            // disableCanvasRotation={this.state.disableCanvasRotation}
            />
            {/* <input
                        name="newImage"
                        type="file"
                        onChange={this.handleNewImage}
                        {...getInputProps()}
                    /> */}



            <br />
            {/* <h3>Props</h3> */}
            Zoom:{' '}
            <input
                name="scale"
                type="range"
                onChange={(e) => setscale(parseFloat(e.target.value))}
                min={'0.5'}
                max="2"
                step="0.01"
                defaultValue="1"
            />
            <br />
            {/* {'Allow Scale < 1'}
             <input
                name="allowZoomOut"
                type="checkbox"
                onChange={this.handleAllowZoomOut}
                checked={this.state.allowZoomOut}
            /> */}
            {/* <br /> */}
            {/* Show grid:{' '}
             <input
                type="checkbox"
                checked={this.state.showGrid}
                onChange={this.handleShowGrid}
            /> */}
            {/* <br /> */}
            {/* Border radius:
            <input
                name="scale"
                type="range"
                onChange={(e) => setborderRadius(parseInt(e.target.value))}
                min="0"
                max="50"
                step="1"
                defaultValue="0"
            />
            <br /> */}
            Width:
            <input
                name="width"
                type="number"
                onChange={(e) => setwidth(parseInt(e.target.value))}
                min="50"
                max="400"
                step="10"
                value={width}
            />
            <br />
            Height:
            <input
                name="height"
                type="number"
                onChange={(e) => setheight(parseInt(e.target.value))}
                min="50"
                max="400"
                step="10"
                value={height}
            />
            <br />
            Rotate:
            <button onClick={rotateLeft}>Left</button>
            <button onClick={rotateRight}>Right</button>
            <br />
            {/* Disable Canvas Rotation
             <input
                name="disableCanvasRotation"
                type="checkbox"
                onChange={this.handleDisableCanvasRotation}
                checked={this.state.disableCanvasRotation}
            /> */}
            {/* <br /> */}
            {/*  Rotation:
            <input
                name="rotation"
                type="range"
                onChange={this.rotateScale}
                min="0"
                max="180"
                step="1"
                defaultValue="0"
            /> */}
            {/* <br /> */}
            {/*   Transparent image?
           <input
                type="checkbox"
                onChange={this.setTransparent}
                defaultChecked={this.state.isTransparent}
            ></input> */}
            {/* <br /> */}
            {/* {this.state.isTransparent && (
                <div style={{ marginLeft: '1rem' }}>
                    Background color:
                    <input
                        name="backgroundColor"
                        type="color"
                        defaultValue={this.state.backgroundColor}
                        onChange={this.setBackgroundColor}
                    />
                    <br />
                </div>
            )} */}
            {/* <br /> */}
            {/* <input type="button" onClick={this.handleSave} value="Preview" /> */}
            {/* <br /> */}
            {editedImage && (

                <img
                    alt=""
                    src={editedImage.img}
                // style={{
                //     borderRadius: `${(Math.min(
                //         editedImage.height,
                //         editedImage.width,
                //     ) +
                //         10) *
                //         (editedImage.borderRadius / 2 / 100)
                //         }px`,
                // }}
                />

            )}
            <Button onClick={handleSave}>Download {fileName}</Button>
        </div>

    )
}

const ResizePage = () => {
    const [selectedFiles, setselectedfiles] = useState([])
    const [loading, setloading] = useState(true)
    const [catagoryName, setcatagoryName] = useState("")

    const onDrop = useCallback((acceptedFiles) => {
        setloading(true)
        let selectedImages = []
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                selectedImages.push(reader.result)
            }
            reader.readAsDataURL(file)
        })
        setloading(false)
        setselectedfiles(selectedImages)
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })


    return (
        <>
            <Grid container alignItems={"center"} justifyContent={"center"}>
                <Grid item sx={12}>
                    <TextField label="Catagory Name" type="text" value={catagoryName} onChange={(e) => setcatagoryName(e.target.value)} />
                    <br />
                </Grid>
                <Grid item sx={12} >
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <h3 style={{ display: "block", width: "95vw", border: "1px solid black", padding: "15px" }} >Drag 'n' drop some files here, or click to select files</h3>
                    </div>
                    <br />
                </Grid>
                <Grid container spacing={1}>
                    {selectedFiles.length > 0 ? selectedFiles.map((imgFile, imgFileIndex) => <Grid key={imgFileIndex} item sx={12} md={6} lg={4}><ImageManipulation imgFile={imgFile} fileName={catagoryName + imgFileIndex} /></Grid>) : null}
                </Grid>
            </Grid>
        </>
    )

}

export default ResizePage
