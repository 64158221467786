import React from 'react'
import ProductCard from './ProductCard';
import { Grid } from '@mui/material';

const Popular = ({ popularProducts, ...rest }) => {

  return (
    <div style={{ margin: "10px" }}>
      <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {popularProducts.map((product, productIndex) =>
          <ProductCard key={productIndex} product={product} productIndex={productIndex} />
        )}
      </div>

    </div>
  )
}

export default Popular