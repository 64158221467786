import React from 'react'
import logo from "../Assets/mpslogo.jpeg"


const VideoCarousel = () => {
  return (
    <section
      className="h-screen bg-Hero bg-cover
    font-[Poppins] md:bg-top bg-center"
    >
      <div className='h-20'></div>
      <div className="flex flex-col justify-end text-centter items-center h-3/4">
        <img
          alt="MPS"
          src={logo}
          className="rounded-md"
          height={100}
          width={100}
        />
        <h1 className="font-serif text-2xl text-white font-bold p-5 text-center">
          MANPASAND SELECTION
        </h1>
        <div className="text-xl text-white p-5 mt-8">
          <h3 style={{
            wordWrap: "break-word",
          }} class="text-center" >Ours is two generations old business since 1980. We acquired so much expertise during the time by delivering best products to our customers. Our shop is located eyesight away from HanumanthNagar NirmalaStore Busstop.</h3>
        </div>
      </div>

    </section>
  )
}

export default VideoCarousel