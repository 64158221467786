import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

import { groupByField } from './ReuseUtils';
import ClipLoader from "react-spinners/ClipLoader";
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import whatsappIcon from "../Assets/whatsapp-whats-app-svgrepo-com.svg"
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography } from '@mui/material';
import ReactWhatsapp from 'react-whatsapp';

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProductDetails = ({ catagoriesData, ...rest }) => {
  const { pid } = useParams()
  const navigate = useNavigate()
  const [product, setproduct] = useState({ name: "", id: "", url: "" });
  const [loading, setloading] = useState(true);
  const [detailDialog, setdetailDialog] = useState(false)

  useEffect(() => {
    let catagoryProductsIdGrouped = groupByField(catagoriesData, "id")
    setproduct(catagoryProductsIdGrouped[pid])
    setloading(false)
    setdetailDialog(true)
  }, [])

  return (
    <>
      {loading ? <ClipLoader
        color={"#800000"}
        loading={loading}
        size={150}
        cssOverride={override}
      /> : <Dialog

        open={detailDialog}
        onClose={() =>
          setdetailDialog(false)}
        TransitionComponent={Transition}>
        <DialogTitle sx={{ background: "blacks" }} style={{ backgroundColor: "#800000" }}>
          <Grid container spacing={1} justifyContent={'space-around'} alignItems={"center"}>
            <Grid item>
              <Typography className="text-xl font-semibold text-white uppercase" gutterBottom variant="h4">
                {product.name ? product.name : ""}
              </Typography>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={5}>

                <ReactWhatsapp number="9481804455" message={window.location.href} >
                  <img className='w-12 h-12 p-1' src={whatsappIcon} alt="WhatsAppShare" />
                </ReactWhatsapp>

                <IconButton
                  edge="start"
                  style={{ color: "#fff" }}
                  onClick={() => { navigate(`/${window.location.pathname.split("/")[1]}`); setdetailDialog(false) }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Stack>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container justifyContent={'space-around'} alignItems={"center"}>
            <Grid item sx={10}>
              <img src={product.url ? product.url : ""} alt="" />
            </Grid>
            <Grid item sx={10}>

              <p class="mb-3 text-lg font-medium text-gray-500 md:text-xl dark:text-gray-400">Please click the whatsapp icon to enquire about the product</p>

            </Grid>
          </Grid>
        </DialogContent>

      </Dialog>
      }
    </>
  )
}

export default ProductDetails