import './App.css';
import Home from "./PageSections/Home"
import ProductList from './PageSections/ProductList';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import ProductDetails from './PageSections/ProductDetails';
import Navbar from './PageSections/Navbar';
import ResizePage from './PageSections/ResizePage';
import UpdateMaker from './PageSections/UpdateUtils/UpdateMaker';
import Footer from './PageSections/Footer';
import ScrollToTop from './PageSections/ScrollToTop';

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};


function App() {
  const [catagoriesData, setcatagoriesData] = useState({});
  const [productCatogories, setproductCatogories] = useState({});
  const [loading, setloading] = useState(true);


  const getProductCatogories = new Promise((resolve, reject) => {
    fetch(window.location.origin + "/data/Catagories.json", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(res => {
        resolve(res.json())
      })
      .catch(err => {
        reject(err)
      });
  }
  )

  const getCatagoriesData = new Promise((resolve, reject) => {
    fetch(window.location.origin + "/data/CatagoriesData.json", {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(res => {
        resolve(res.json())
      })
      .catch(err => {
        reject(err)
      });
  })


  useEffect(() => {
    Promise.all([
      getProductCatogories,
      getCatagoriesData])
      .then(res => {
        setproductCatogories(res[0])
        setcatagoriesData(res[1])
        setloading(false)
      })
      .catch(err => {
        setloading(false)
      });

    // setproductCatogories(window.productCatogories)
    // setcatagoriesData(window.catagoriesData)


    // }, [catagoriesData, productCatogories])
  }, [])

  return (
    <>
      {loading ? <ClipLoader
        color={"#800000"}
        loading={loading}
        size={150}
        cssOverride={override}
      /> :
        <>
          <Navbar catagories={productCatogories} />
          <Routes>
            <Route path="/" element={<Home catagories={productCatogories} catagoriesData={catagoriesData} />} />
            {Object.keys(productCatogories).map(
              (section, sectionIndex) => productCatogories[section].map(
                (catagory, catagoryIndex) => <Route path={`${catagory.name}`} element={<ProductList catagoriesData={catagoriesData[catagory.name]} catagoryId={catagory.name} />} >
                  <Route path=":pid" element={<ProductDetails catagoriesData={catagoriesData[catagory.name]} />} />
                </Route>
              )
            )}
            <Route path="/resize" element={<ResizePage />} />
            <Route path="/update" element={<UpdateMaker catagories={productCatogories} catagoriesData={catagoriesData} />} />
            <Route path="/*" element={<Home catagories={productCatogories} catagoriesData={catagoriesData} />} />
          </Routes>
          {/* <ScrollToTop /> */}
          <Footer catagories={productCatogories} />
        </>
      }</>
  )
}

export default App;
