import React, { useState } from "react";
import { Link } from "react-router-dom";

const NavLinks = ({ catagories, closeOpen, ...rest }) => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  return (
    <>
      {Object.keys(catagories).map((section) => (
        <div>
          <div className="px-3 text-left md:cursor-pointer group">
            <h3
              className="py-7 text-black flex justify-between items-center md:pr-0 pr-5 group"
              onClick={() => {
                heading !== section ? setHeading(section) : setHeading("");
                setSubHeading("");
              }}
            >
              {section}
            </h3>
            {catagories[section].length > 0 && (
              <div>
                <div className="absolute top-20 hidden group-hover:md:block hover:md:block">
                  <div className="bg-white p-5 grid grid-cols-1 gap-10">
                    {catagories[section].map((subcatagory) => (

                      <Link
                        to={`/${subcatagory.name}`}
                        className="hover:text-primary text-black"
                      >
                        {subcatagory.name}
                      </Link>

                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === section ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {catagories[section].map((subCatagory) => (
              <div className="p-3"
                onClick={() =>
                  subHeading !== subCatagory.name
                    ? setSubHeading(subCatagory.name)
                    : setSubHeading("")
                }
              >
                <Link to={`/${subCatagory.name}`} onClick={() => closeOpen()}>{subCatagory.name}</Link>
              </div>

            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
