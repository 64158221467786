import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';


export default function ProductCard({ product, productIndex,removeMe,catagory, ...rest }) {
    const navigate = useNavigate()
    return (

        <Grid item sx={6} md={4} lg={3}>

            <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                    sx={{ height: 400, width: 300 }}
                    image={product.url}
                />
                <CardContent>
                    <Typography className='uppercase' gutterBottom variant="h5" component="div">
                        {product.name}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={(e) => removeMe(productIndex,catagory)} variant='outlined'>{`Remove`}</Button>
                </CardActions>
            </Card>
        </Grid>



    );
}