import { Grid } from '@mui/material'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import fbIcon from "../Assets/facebook-svgrepo-com.svg"
import googleIcon from "../Assets/google-color-svgrepo-com.svg"
import instaIcon from "../Assets/instagram-svgrepo-com.svg"



const Footer = ({ catagories, ...rest }) => {
  const navigate = useNavigate()

  return (

    <Grid container spacing={2} style={{ backgroundColor: "#800000", padding: "5px" }}>
      {Object.keys(catagories).map((catagory, catagoryIndex) =>
        <Grid key={catagoryIndex} item xs={12} md={6} lg={4}>
          <h5 style={{ color: "white", fontWeight: "bolder" }}>{catagory}</h5>
          <ul>
            {catagories[catagory].map((subCatagory, subCatagoryIndex) =>
              <li className="py-1 no-underline cursor-pointer list-none" key={subCatagoryIndex} style={{ color: "white" }} onClick={(e) => { e.preventDefault(); navigate(`/${subCatagory.name}`); window.scrollTo(0, 0) }}>
                {/* <Link  to={`/products/#${subCatagory.name}`} underline="hover"> */}
                {subCatagory.name}
                {/* </Link> */}
              </li>
            )}
          </ul>

        </Grid>
      )}
      <Grid item container xs={12} md={6} lg={4} spacing={3}>

        <h5 style={{ marginLeft: "30px", color: "white", fontWeight: "bolder" }}>{"Follow us on"}</h5>


        <Grid item>
          <a href={"https://www.facebook.com/profile.php?id=100084539482517&mibextid=ZbWKwL"}>
            <img className='rounded-full w-7 h-7' src={fbIcon} alt="Facebook" />
          </a>
        </Grid>

        <Grid item>
          <a href={"https://instagram.com/selectionmanpasand?igshid=ZDdkNTZiNTM="}>
            <img className='rounded-full w-7 h-7' src={instaIcon} alt="Instagram" />
          </a>
        </Grid>

        <Grid item>
          <a href={"https://www.google.com/search?q=manpasand+selection&oq=MAnpasand+s&aqs=chrome.0.0i355i512j46i175i199i512j0i512j69i57j0i512j69i60l3.16303j0j7&sourceid=chrome&ie=UTF-8"}>
            <img className='rounded-full w-7 h-7' src={googleIcon} alt="Google" />
          </a>
        </Grid>

      </Grid>
    </Grid>

  )
}

export default Footer