import React from 'react'
import Popular from './Popular';
import Contacts from './Contacts';
import { Typography } from '@mui/material';
import VideoCarousel from './VideoCarousel';

const Home = ({ catagoriesData }) => {
    return (
        <div>
            <VideoCarousel />
            <Typography variant='h4' style={{ padding: "5px" }}>Popular Products</Typography>
            <Popular popularProducts={!!catagoriesData.Popular ? catagoriesData.Popular : []} />
            <Typography variant='h4' style={{ padding: "5px" }}>Contact Details</Typography>
            <Contacts />
        </div>
    );
}

export default Home