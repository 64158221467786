import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import ProductCard from './ProductCard';

const ProductList = ({ catagoriesData, catagoryId, ...rest }) => {
    return (
        <>
            <div className="flex items-center justify-around py-3 bg-primary" >
                <Typography className='uppercase text-white' variant='h4'>{`${catagoryId}`}</Typography>
            </div>
            <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                {
                    catagoriesData.map((product, productIndex) =>
                        <ProductCard key={productIndex} product={product} productIndex={productIndex} />
                    )
                }

            </div>
            <Outlet />
        </>
    )
}

export default ProductList