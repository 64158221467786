import { Grid } from '@mui/material'
import React from 'react'

const Contacts = () => {
    return (
        <Grid container spacing={1} style={{ minHeight: "400px" }} alignItems="center" justifyContent={"center"}>
            <Grid item sx={12} md={6}>
                <div style={{ overflow: "scroll" }}>
                    <iframe width="300" height="300" frameborder="0" scrolling="no" marginHeight="0" marginWidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=300&amp;height=300&amp;hl=en&amp;q=72,%20Kumaraswamy%20Temple%20Rd,%20SBM%20Colony,%20Banashankari%201st%20Stage%20Bengaluru+(ManpasandSelection)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                </div>
            </Grid>
            <Grid item sx={12} md={6}>
                <div className='flex flex-col justify-center text-center items-center'>
                    <h3>Contact No: 9481804455,</h3>
                    <h3 style={{
                        wordWrap: "break-word",
                    }}>20 ,12th Main 50-feet Road  Near Nirmala Store Bus Stand, Bengaluru, Karnataka, PIN:560050, India.</h3>

                </div>
            </Grid>
        </Grid>
    )
}

export default Contacts