import React, { useCallback, useRef, useState } from 'react'
import { Accordion, Grid, AccordionSummary, AccordionDetails, Typography, Button, Dialog, DialogTitle, DialogActions, DialogContent, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductCard from './ProductCard';
import { useDropzone } from 'react-dropzone';
import { saveAs } from 'filesaver.js-npm';
import AvatarEditor from 'react-avatar-editor';
import { useEffect } from 'react';


const ImageManipulation = ({ imgFile, fileName, downloadTrigger, downloadTriggerDissable, ...rest }) => {
    const editor = useRef()
    const [position, setposition] = useState({ x: 0.5, y: 0.5 })
    const [allowZoomOut, setallowZoomOut] = useState(false)
    const [scale, setscale] = useState(1)
    const [rotate, setrotate] = useState(0)
    const [borderRadius, setborderRadius] = useState(0)
    const [width, setwidth] = useState(300)
    const [height, setheight] = useState(400)
    const [isTransparent, setisTransparent] = useState(false)
    const [showGrid, setshowGrid] = useState(false)
    const [editedImage, seteditedImage] = useState(undefined)

    const handleSave = () => {
        const img = editor.current?.getImageScaledToCanvas()

        const rect = editor.current?.getCroppingRect()

        if (!img || !rect) return

        seteditedImage(
            {
                img,
                rect,
                scale: scale,
                width: width,
                height: height,
                borderRadius: borderRadius,
            }
        )
        img.toBlob((blob) => saveAs(blob, fileName))
    }

    useEffect(() => {
        if (downloadTrigger) {
            handleSave()
            downloadTriggerDissable()
        }
    }, [downloadTrigger])

    const rotateLeft = (e) => {
        e.preventDefault()
        setrotate((rotate - 90) % 360)
    }

    const rotateRight = (e) => {
        e.preventDefault()
        setrotate((rotate + 90) % 360)
    }
    const handlePositionChange = (position) => {
        setposition(position)
    }




    return (

        <div>
            <AvatarEditor
                ref={editor}
                scale={scale}
                width={width}
                height={height}
                position={position}
                // showGrid={showGrid}
                onPositionChange={handlePositionChange}
                rotate={rotate}
                borderRadius={
                    width / (100 / borderRadius)
                }
                image={imgFile}
            // backgroundColor={this.state.backgroundColor}
            // onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
            // onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
            // onImageReady={this.logCallback.bind(this, 'onImageReady')}
            // disableCanvasRotation={this.state.disableCanvasRotation}
            />
            {/* <input
                        name="newImage"
                        type="file"
                        onChange={this.handleNewImage}
                        {...getInputProps()}
                    /> */}



            <br />
            {/* <h3>Props</h3> */}
            Zoom:{' '}
            <input
                name="scale"
                type="range"
                onChange={(e) => setscale(parseFloat(e.target.value))}
                min={'0.5'}
                max="2"
                step="0.01"
                defaultValue="1"
            />
            <br />
            {/* {'Allow Scale < 1'}
             <input
                name="allowZoomOut"
                type="checkbox"
                onChange={this.handleAllowZoomOut}
                checked={this.state.allowZoomOut}
            /> */}
            {/* <br /> */}
            {/* Show grid:{' '}
             <input
                type="checkbox"
                checked={this.state.showGrid}
                onChange={this.handleShowGrid}
            /> */}
            {/* <br /> */}
            {/* Border radius:
            <input
                name="scale"
                type="range"
                onChange={(e) => setborderRadius(parseInt(e.target.value))}
                min="0"
                max="50"
                step="1"
                defaultValue="0"
            />
            <br /> */}
            Width:
            <input
                name="width"
                type="number"
                onChange={(e) => setwidth(parseInt(e.target.value))}
                min="50"
                max="400"
                step="10"
                value={width}
            />
            <br />
            Height:
            <input
                name="height"
                type="number"
                onChange={(e) => setheight(parseInt(e.target.value))}
                min="50"
                max="400"
                step="10"
                value={height}
            />
            <br />
            Rotate:
            <button onClick={rotateLeft}>Left</button>
            <button onClick={rotateRight}>Right</button>
            <br />
            {/* Disable Canvas Rotation
             <input
                name="disableCanvasRotation"
                type="checkbox"
                onChange={this.handleDisableCanvasRotation}
                checked={this.state.disableCanvasRotation}
            /> */}
            {/* <br /> */}
            {/*  Rotation:
            <input
                name="rotation"
                type="range"
                onChange={this.rotateScale}
                min="0"
                max="180"
                step="1"
                defaultValue="0"
            /> */}
            {/* <br /> */}
            {/*   Transparent image?
           <input
                type="checkbox"
                onChange={this.setTransparent}
                defaultChecked={this.state.isTransparent}
            ></input> */}
            {/* <br /> */}
            {/* {this.state.isTransparent && (
                <div style={{ marginLeft: '1rem' }}>
                    Background color:
                    <input
                        name="backgroundColor"
                        type="color"
                        defaultValue={this.state.backgroundColor}
                        onChange={this.setBackgroundColor}
                    />
                    <br />
                </div>
            )} */}
            {/* <br /> */}
            {/* <input type="button" onClick={this.handleSave} value="Preview" /> */}
            {/* <br /> */}
            {editedImage && (

                <img
                    alt=""
                    src={editedImage.img}
                // style={{
                //     borderRadius: `${(Math.min(
                //         editedImage.height,
                //         editedImage.width,
                //     ) +
                //         10) *
                //         (editedImage.borderRadius / 2 / 100)
                //         }px`,
                // }}
                />

            )}
            <Button onClick={handleSave}>Download {fileName}</Button>
        </div>

    )
}

const UpdateMaker = (props) => {
    const [selectedFiles, setselectedfiles] = useState([])
    const [loading, setloading] = useState(true)
    const [catagoryName, setcatagoryName] = useState("")
    const [downloadTrigger, setdownloadTrigger] = useState(false)
    const [resizeDialog, setresizeDialog] = useState(false)
    const [catagories, setcatagories] = useState(props.catagories)
    const [catagoriesData, setcatagoriesData] = useState(props.catagoriesData)
    const [newCatagoriesData, setnewCatagoriesData] = useState({})
    const [newCatagoryName, setnewCatagoryName] = useState("")
    const [openAddCatagoryDialog, setopenAddCatagoryDialog] = useState(false)
    const [selectedSection, setselectedSection] = useState("")
    const [addProductsCatagory, setaddProductsCatagory] = useState("")

    const onDrop = useCallback((acceptedFiles) => {
        setloading(true)
        let selectedImages = []
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                selectedImages.push(reader.result)
            }
            reader.readAsDataURL(file)
        })
        setloading(false)
        setselectedfiles(selectedImages)
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })
    const downloadTriggerDissable = () => {
        setdownloadTrigger(false)
    }
    const updateCatagories = () => {
        let tempcatagories = { ...catagories }
        tempcatagories[selectedSection] = [...tempcatagories[selectedSection], { id: tempcatagories[selectedSection].length + 1, name: newCatagoryName }]
        setcatagories(tempcatagories)
        setcatagoriesData({ ...catagoriesData, [newCatagoryName]: [] })
        setopenAddCatagoryDialog(false)

    }
    const removeCatagory = (section, catagoryIndex) => {
        let tempcatagories = { ...catagories }

        tempcatagories[section].splice(catagoryIndex, 1)

        setcatagories(tempcatagories)
    }
    const removeProductFromcatagoriesDataObj = (productIndex, catagory) => {
        let tempcatagoriesData = { ...catagoriesData }
        tempcatagoriesData[catagory.name].splice(productIndex, 1)
        setcatagoriesData(tempcatagoriesData)
    }
    const removeProductFromNewCatagoriesDataObj = (productIndex, catagory) => {
        let tempcatagoriesData = { ...newCatagoriesData }
        tempcatagoriesData[catagory.name].splice(productIndex, 1)
        setnewCatagoriesData(tempcatagoriesData)
    }

    const addNewProducts = () => {
        let tempNewCatagoriesData = { ...newCatagoriesData }

        let tempCatagory = []
        if (tempNewCatagoriesData.hasOwnProperty(addProductsCatagory)) {
            tempCatagory = [...tempNewCatagoriesData[addProductsCatagory]]
        }

        for (let selectedFileIndex = 0; selectedFileIndex < selectedFiles.length; selectedFileIndex++) {
            let productId = catagoriesData[addProductsCatagory].length + selectedFileIndex
            tempCatagory.push({ id: productId, name: `${addProductsCatagory} ${productId}`, url: `https://manpasandselection.com/Assets/${addProductsCatagory}/${addProductsCatagory} ${productId}.png` })
        }

        tempNewCatagoriesData = { ...tempNewCatagoriesData, [addProductsCatagory]: [...tempCatagory] }
        setnewCatagoriesData(tempNewCatagoriesData)
    }

    const downloadUploadFiles = () => {
        // productsData
        let downloadProductsdata = {}
        Object.keys(catagories).map((section) => {

            catagories[section].map((catagory) => {
                let downloadCatagoryData = []
                let olddataLength = catagoriesData.hasOwnProperty(catagory.name) ? catagoriesData[catagory.name].length : 0
                let newdataLength = newCatagoriesData.hasOwnProperty(catagory.name) ? newCatagoriesData[catagory.name].length : 0
                for (let productIndex = 0; productIndex < olddataLength; productIndex++) {
                    if (olddataLength > 0) {
                        downloadCatagoryData.push({ id: productIndex + 1, name: `${catagory.name} ${productIndex + 1}`, url: `https://manpasandselection.com/Assets/${catagory.name}/${catagory.name} ${productIndex + 1}.png` })
                    }
                }
                for (let productIndex = 0; productIndex < newdataLength; productIndex++) {

                    downloadCatagoryData.push({ id: productIndex + olddataLength + 1, name: `${catagory.name} ${productIndex + olddataLength}`, url: `https://manpasandselection.com/Assets/${catagory.name}/${catagory.name} ${productIndex + olddataLength}.png` })
                }
                downloadProductsdata = { ...downloadProductsdata, [catagory.name]: downloadCatagoryData }
            })

        })
        // download

        let catagoriesDataSave = new Blob([JSON.stringify(downloadProductsdata)], {
            type: 'application/json'
        });
        let catagoriesSave = new Blob([JSON.stringify(catagories)], {
            type: 'application/json'
        });

        // Save the file
        saveAs(catagoriesDataSave, "CatagoriesData.json");
        saveAs(catagoriesSave, "Catagories.json");
    }


    return (
        <div>
            <Button onClick={downloadUploadFiles}>Download UploadData</Button>
            {Object.keys(catagories).map((section, sectionIndex) =>

                <Accordion defaultExpanded key={sectionIndex}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"

                    >
                        <Typography variant='h4'>{section}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container alignItems={"center"} justifyContent={"center"}>
                            <Grid item sx={12}>
                                <Button onClick={(e) => { setopenAddCatagoryDialog(true); setselectedSection(section) }} variant='outlined'>{`Add New Catagory to ${section}`}</Button>
                            </Grid>

                        </Grid>
                        {catagories[section].map((catagory, catagoryIndex) =>
                            <Accordion key={catagoryIndex}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography variant='h6'>{catagory.name}</Typography>


                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container alignItems={"center"} justifyContent={"center"}>
                                        <Grid item sx={12}>
                                            <Button onClick={(e) => { setaddProductsCatagory(catagory.name); setresizeDialog(true) }} variant='outlined'>{`Add new Products to "${catagory.name}"`}</Button>
                                        </Grid>
                                        <Grid item sx={12}>
                                            <Button onClick={(e) => removeCatagory(section, catagoryIndex)} variant='outlined'>{`Remove Catagory`}</Button>
                                        </Grid>
                                    </Grid>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography variant='h6'>{"Old"}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={1} justifyContent={'center'} alignItems={"center"}>
                                                {
                                                    catagoriesData.hasOwnProperty(catagory.name) ? catagoriesData[catagory.name].map((product, productIndex) =>

                                                        <ProductCard key={productIndex} catagory={catagory} product={product} productIndex={productIndex} removeMe={removeProductFromcatagoriesDataObj} />

                                                    ) : null

                                                }
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography variant='h6'>{"New"}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container alignItems={"center"} justifyContent={"center"}>
                                                {
                                                    newCatagoriesData.hasOwnProperty(catagory.name) ? newCatagoriesData[catagory.name].map((product, productIndex) =>


                                                        <ProductCard key={productIndex} catagory={catagory} product={product} productIndex={productIndex} removeMe={removeProductFromNewCatagoriesDataObj} />

                                                    ) : null
                                                }
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </AccordionDetails>
                            </Accordion>
                        )}
                    </AccordionDetails>
                </Accordion>
            )}
            <Dialog
                fullWidth={true}
                maxWidth={"xl"}
                open={resizeDialog}
                onClose={() => setresizeDialog(false)}
            >
                <DialogTitle>Manipulate Upload Images</DialogTitle>
                <DialogContent>
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                        <Grid item sx={12} >
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <h3 style={{ display: "block", width: "300", height: "300", border: "1px solid black", padding: "15px" }} >Drag 'n' drop some files here, or click to select files</h3>
                            </div>
                            <br />
                        </Grid>
                        <Grid container spacing={1}>
                            {selectedFiles.length > 0 ? selectedFiles.map((imgFile, imgFileIndex) => <Grid key={imgFileIndex} item sx={12} md={6} lg={4}>
                                <Button onClick={(e) => {
                                    let tempselectedFiles = [...selectedFiles]
                                    tempselectedFiles.splice(imgFileIndex, 1)
                                    setselectedfiles(tempselectedFiles)
                                }} variant='outlined'>{`Remove`}</Button>
                                <ImageManipulation imgFile={imgFile} downloadTriggerDissable={downloadTriggerDissable} downloadTrigger={downloadTrigger} fileName={catagoryName + imgFileIndex} /></Grid>) : null}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                        <Grid item sx={4}>
                            <Button onClick={(e) => { addNewProducts(); setdownloadTrigger(true) }} variant='outlined'>Download All</Button>
                        </Grid>
                        <Grid item sx={4}>
                            <Button onClick={(e) => { setresizeDialog(false); setselectedfiles([]) }} variant='outlined'>Cancel</Button>
                        </Grid>
                        <Grid item sx={4}>
                            <Button onClick={(e) => { setresizeDialog(false); setselectedfiles([]) }} variant='outlined'>Close</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={"sm"}
                open={openAddCatagoryDialog}
                onClose={(e) => setopenAddCatagoryDialog(false)}
            >
                <DialogTitle>Catagory Addition</DialogTitle>
                <DialogContent>
                    {/* <Grid container alignItems={"center"} justifyContent={"center"} className='w-full'>
                        <Grid item sx={12} className='pt-1.5 wx-60px'> */}
                    <TextField onChange={(e) => setnewCatagoryName(e.target.value)} fullWidth={true} id="outlined-basic" label="Catagory name" variant="outlined" value={newCatagoryName} />
                    {/* </Grid>
                    </Grid> */}
                </DialogContent>
                <DialogActions>
                    <Grid container alignItems={"center"} justifyContent={"center"}>
                        <Grid item sx={12}>
                            <Button onClick={(e) => updateCatagories(e)} variant='outlined'>Add</Button>
                        </Grid>
                        <Grid item sx={12}>
                            <Button onClick={(e) => { setopenAddCatagoryDialog(false); setnewCatagoryName("") }} variant='outlined'>Cancel</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UpdateMaker