import { Close, Menu } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/mpslogo.png"
import NavLinks from "./NavLinks";

const Navbar = ({ catagories, ...rest }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate()
  return (
    <nav className="bg-white" >
      <div className="flex items-center font-medium justify-around">
        <div className="z-50 p-2 md:w-auto w-full flex justify-between">
          <img src={Logo} alt="logo" className="md:cursor-pointer" width={"70px"} height={"70px"} onClick={(e) => {
            e.preventDefault()
            navigate(`/`)
          }} />
          <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
            <IconButton name={`${open ? "close" : "menu"}`}>{open ? <Close /> : <Menu />}</IconButton>
          </div>
        </div>
        <ul className="md:flex hidden uppercase items-center gap-8 font-[Poppins]">
          <h3 onClick={(e) => {
            e.preventDefault()
            navigate(`/`)
          }} className="cursor-pointer">
            Home
          </h3>
          <NavLinks catagories={catagories} closeOpen={() => setOpen(false)} />
        </ul>
        {/* Mobile nav */}
        <ul
          className={`
        md:hidden z-500 bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-110%]"}
        `}
        >
          <h3 onClick={(e) => {
            e.preventDefault()
            navigate(`/`)
          }} className="cursor-pointer">
            Home
          </h3>
          <NavLinks catagories={catagories} />
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
