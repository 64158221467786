import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';


export default function ProductCard({ product, productIndex, ...rest }) {
    const navigate = useNavigate()
    return (

        <div className=" rounded-lg m-1 shadow-2xl lg:max-w-sm" key={productIndex}>
            <img
                className="object-cover w-full h-400px"
                src={product.url}
                alt="image"
            />
            <div className="p-4">
                <h4 className="text-xl font-semibold text-black-600 uppercase">

                    {product.name}
                </h4>
                {/* <p className="mb-2 leading-normal">
         {items.content}
     </p> */}
                <button className="px-4 py-2 text-base text-white bg-primary rounded shadow"
                    onClick={(e) => {
                        navigate(`${window.location.pathname !== "/" ? window.location.pathname : window.location.pathname + "popular"}/${product.id}`)
                    }}>
                    Show Details
                </button>
            </div>
        </div>


    );
}